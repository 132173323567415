import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as variable from "../components/variables"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll-v2"
import "../components/scss/page/home.scss"
import "../components/scss/page/networkMonitoring.scss"
import SEO from "../components/seo"
import bgimage from "../images/background2.png"
import topMS from "../images/bg-ms-cloud-solution-partner5.png"
import heroImage from "../images/right_hero-image-_1.png"
import comparisonBG from "../images/pattern.png"
import mdLogo from "../images/md-logo.png"
import pdsLogo from "../images/PDSLogo2.png"
import mdLogoIcon from "../images/md-logo-icon.png"
import pdsLogoIcon from "../images/PDSLogoIcon.png"
import greenCheck from "../images/greencheck2.png"
import redEx from "../images/redex.png"
import midBG from "../images/element_blue-bar-slanted.png"
import bottomBG from "../images/bottom-elements.png"
import carrotLeftPublicURL from "../images/faq-right.png"
import carrotDownPublicURL from "../images/faq-down.png"
import backgroundLights from "../images/lights1.png"
import backgroundLights2 from "../images/lights2.png"

import lightsBar from "../images/network-light-bar.png"
import centerGear from "../images/desktopGear.png"
import centerGearMobile from "../images/gearMobile.png"
import centerGearTablet from "../images/tabletGear.png"
import highlightOverlay from "../images/desktopOverlay.png"
import highlightOverlayTablet from "../images/tabletOverlay.png"
import highlightOverlayMobile from "../images/mobileOverlay.png"

import insights from "../images/insight.png"
import detection from "../images/detection.png"
import clear from "../images/clear.png"
import freedom from "../images/freedom.png"
import iconCheck from "../images/icon_check.png"
import growth from "../images/growth.png"
import mail from "../images/nm-mail.png"
import phone from "../images/nm-phone.png"
import fax from "../images/nm-fax.png"
import twitter from "../images/network-twitter.png"
import linkedin from "../images/network-linkedin.png"

import Container from "../components/container"
import { highlight } from "prismjs"

const PageStyle = styled.div`
  /* background-image: url(${bgimage}); */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: #122E4C; */
  background: linear-gradient(180deg, #091B2E 7.81%, rgba(18, 46, 76, 0.3) 76.56%, #091B2E 100%), #122E4C;
  section {
    padding: ${variable.sectionPadding} 0px;
  }
  .background-lights{
    background-image: url(${backgroundLights});
    background-size:contain;
    position:absolute;
    z-index: 0;
    pointer-events: none;
    background-repeat:repeat-y;
    background-position:center top;
    /* z-index:-1; */
    width:100%;
    height: calc(100% - 100px);
    top: 100px;
    mix-blend-mode: overlay;
  }
  .nm-container-inner {
    padding: 0px 0px 0px 0px;
    height:100%;
    position:relative;
    font-family:"Inter" !important;
    *{
      font-family:"Inter" !important;
    }
  }
  .nm-peace{
      color:rgb(181, 0, 41);
      }
  .nm-hero-outer{
    padding:130px 0px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    z-index:2;
    position:relative;
    @media (max-width: ${variable.tabletWidth}) {
      padding:95px 0px;
    }
    @media (max-width: ${variable.mobileWidth}) {
      padding:80px 0px;
    }
    h2{
      color:white;
      font-size:64px;
      font-weight:900;
      line-height:1.2;
      max-width:939px;
      @media (max-width: ${variable.tabletWidth}) {
        font-size:36px;
        max-width:576px;
      }
      @media (max-width: ${variable.mobileWidth}) {
        font-size:28px;
        max-width:350px;
      }
    }
    .contact-link-container{
      display:flex;
      justify-content:center;
      .cta-button{
        background-color:#B50029;
        border-radius:0px;
        padding: 11px 45px;
        font-size:20px;
        @media (max-width: ${variable.tabletWidth}) {
          font-size:16px;
        }
      }
    }
  }
  .nm-causes-outer{
    z-index:2;
    position:relative;
    background-image:url(${lightsBar});
      background-size:contain;
      background-repeat:no-repeat;
      background-position: center bottom;
}
    .nm-causes-inner{
      background-color:#07182a;
      border:20px solid #112034;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      padding:80px 20px 50px 20px;
      background: radial-gradient(41.93% 41.93% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #07182A;
background-blend-mode: overlay, normal;
@media (max-width: ${variable.mobileWidth}) {
  background:rgb(9, 25, 42);
  /* background:none; */

}
h2{
  color:white;
  text-align:center;
  font-weight:900;
  font-size:40px;
  line-height:48px;
  width:100%;
  @media (max-width: ${variable.tabletWidth}) {
    font-size:32px;
    line-height:39px;
  }
}
.nm-cause-org{
  color:#A7ADB4;
  font-size:24px;
  font-weight:600;
  line-height:36px;
  text-align:center;
  @media (max-width: ${variable.tabletWidth}) {
    font-size:16px;
    line-height:24px;
  }
}
.causes-top-box-container{
  display:flex;
  justify-content:space-between;
  /* margin-top:60px; */
  padding:0px 20px;
  flex-wrap:wrap;
  position:relative;
  top:3%;
  z-index:2;
  margin-top:80px;
    @media (max-width: ${variable.tabletWidth}) {
      position:relative;
      top:0%;
    }
  .causes-top-box{
    width:calc(100% / 4 - 15px);
    background-color:#283F56;
    border: 3px solid #405974;
    box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.5);
    padding:20px;
    text-align:center;
    flex-wrap:wrap;
    @media (max-width: ${variable.tabletWidth}) {
      padding: 20px 10px 20px 10px;
    }
    @media (max-width: ${variable.mobileWidth}) {
      width:calc(100% / 2 - 10px);
      &:nth-child(1){
        margin-bottom:20px;
      }
      &:nth-child(2){
        margin-bottom:20px;
      }
    }
    @media (max-width: ${variable.mobileWidthSmall}) {
      width:calc(100%);
      margin-bottom:20px;
      &:last-child{
        margin-bottom:0px;
      }
    }
    h2{
      font-size:17px;
      font-weight:700;
      line-height:1.2;
      margin:0px 0px 5px 0px;
    }
    .causes-top-copy{
      font-weight:400;
      font-size:15px;
      line-height:1.2;
      color:#A7ADB4;
    }
  }
}
  }

  }
  .center-gear-container{
    /* background-image: url(${highlightOverlay}), url(${centerGear});
    background-position:center center;
    background-size:105%;
    background-repeat:no-repeat;
    height:930px;
    margin-top:40px; */
    /* mix-blend-mode: normal, overlay; */
    /* background-blend-mode: normal, overlay; */
    /* @media (max-width: ${variable.tabletWidth}) {
      background-image: url(${centerGearTablet});
      height:830px;
      margin-top:70px;
    }
    @media (max-width: ${variable.mobileWidth}) {
      background-image: none;
      height:auto;
    } */
    position:relative;
    .centerGearOverlay{
      background-image: url(${highlightOverlay});
      position:absolute;
      height:130%;
      width:100%;
      mix-blend-mode: overlay;
      background-size:105%;
      background-position:center 220px;
      background-repeat:no-repeat;
      opacity: 0.5;
      @media (max-width: ${variable.tabletWidth}) {
        background-image: url(${highlightOverlayTablet});
        height: 120%;
        background-size: 85%;
    background-position: center 59%;
      }
      @media (max-width: ${variable.mobileWidth}) {
        background-image: url(${highlightOverlayMobile});
        background-position: center 53%;
    height: 106%;
    background-size: 125%;
      }
      @media (max-width: 750px) {
        background-image: url(${highlightOverlayMobile});
        background-position: center 53%;
        background-size: 125%;
      }
      @media (max-width: 700px) {
        background-image: url(${highlightOverlayMobile});
        background-position: center 52%;
        background-size: 125%;
      }
      @media (max-width: ${variable.mobileWidthSmall}) {
        background-image: url(${highlightOverlayMobile});
        background-position:center 50%;
        height: 106%;
    background-size: 135%;
      }
    }
    .mobile-middle-gear{
      display:none;
      @media (max-width: ${variable.mobileWidth}) {
        display:block;
        position:relative;
        .mobile-middle-copy{
      width: 100%;
        text-align:center;
        color:#ffffff !important;
        padding:30px 50px;
        align-self:flex-end;
        background: rgb(12,32,53);
        font-size:12px;
        line-height:21px;
        font-weight:600;
background: linear-gradient(90deg, rgba(12,32,53,0) 0%, rgba(12,32,53,1) 20%, rgba(12,32,53,1) 80%, rgba(12,32,53,0) 100%);
box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.38);
position: absolute;
    bottom: 27%;
        }

    }
    }
    .center-gear-middle-middle-new{
        width: 515px;
        text-align:center;
        color:#ffffff !important;
        padding:30px 50px;
        align-self:flex-end;
        background: rgb(12,32,53);
        font-size:17px;
        line-height:30px;
        font-weight:600;
background: linear-gradient(90deg, rgba(12,32,53,0) 0%, rgba(12,32,53,1) 20%, rgba(12,32,53,1) 80%, rgba(12,32,53,0) 100%);
box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.38);
position: relative;
    bottom: 30px;
    @media (max-width: ${variable.tabletWidth}) {
      bottom: 0%;
      padding:15px 30px;
    }
    @media (max-width: ${variable.mobileWidth}) {
      bottom: 35%;
      padding:10px 30px;
      font-size:12px;
      line-height:21px;
      width:100%;
      position:absolute;
      max-width:350px;
    }
  }
    .center-gear-middle{
     /* padding-top:120px; */
     /* position:absolute; */
     /* top:25%; */
     /* margin-top:11%; */
     position:absolute;
     top:20%;
     width:100%;
     @media (max-width: ${variable.tabletWidth}) {
      padding-top:0px;
      position: absolute;
      top: 66%;
     }
     @media (max-width: ${variable.mobileWidth}) {
      /* display:none; */
      top:50%;
     }
     .center-gear-middle-inner{
      display:flex;
      justify-content:space-between;
      width:82%;
      max-width:950px;
      margin:0 auto;
      height:350px;
      @media (max-width: ${variable.tabletWidth}) {
        justify-content:center;
        height:100%;
        align-items: center;
}
@media (max-width: ${variable.mobileWidth}) {
        /* display:none; */
}
      .center-gear-left{
        width:140px;
        align-self:center;
        @media (max-width: ${variable.tabletWidth}) {
          display:none;
        }
        div{
          padding:20px 0px ;
          color:#254F7B !important;
          font-size:16px;
          font-weight:700;
        }
      }
      .center-gear-middle-middle{
        width: 515px;
        text-align:center;
        color:#ffffff !important;
        padding:30px 50px;
        align-self:flex-end;
        background: rgb(12,32,53);
        font-size:17px;
        line-height:30px;
        font-weight:600;
background: linear-gradient(90deg, rgba(12,32,53,0) 0%, rgba(12,32,53,1) 20%, rgba(12,32,53,1) 80%, rgba(12,32,53,0) 100%);
box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.38);
position: relative;
    bottom: 30px;
    @media (max-width: ${variable.tabletWidth}) {
      bottom: 40%;
      padding:15px 30px;
    }
    @media (max-width: ${variable.mobileWidth}) {
      bottom: 65%;
      padding:10px 30px;
      font-size:12px;
      line-height:21px;
      width:100%;
    }
*{
  color:#ffffff !important;

}
      }
      .center-gear-right{
        width:140px;
        align-self:center;
        @media (max-width: ${variable.tabletWidth}) {
          display:none;
        }
        div{
          padding:20px 0px ;
          color:#254F7B !important;
          font-size:16px;
          font-weight:700;
          text-align:right;
        }
      }
     }
    }
  }
  .causes-bottom-box-container{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    /* margin-top: 35px; */
    padding: 0px 20px;
    flex-wrap:wrap;
    position:relative;
    top:2%;
    z-index:2;
    @media (max-width: ${variable.tabletWidth}) {
      top:0%;
      margin-top: 7%;
    }
    @media (max-width: ${variable.mobileWidth}) {
      top:0%;
      margin-top: 0%;
    }
    .causes-bottom-box{
      width: calc(25% - 15px);
    background-color: #990828;
    border: 1px solid #B70029;    
    box-shadow: rgba(0, 0, 0, 0.5) 0px 8px 0px;
    box-shadow: 0px 6px 0px rgba(0, 14, 29, 0.3), 0px 6px 0px rgba(106, 0, 24, 0.8);
    padding: 20px 10px;
    display:flex;
    color:white !important;
    align-items:center;
    font-size:15px;
    font-weight:400;
    line-height:1.2;
    @media (max-width: ${variable.tabletWidth}) {
      font-size:15px;
      padding:20 10px;
      flex-direction:column;
      text-align:center;
    }
    @media (max-width: ${variable.mobileWidth}) {
      width:calc(100% / 2 - 10px);
      margin-bottom:20px;
    }
    @media (max-width: ${variable.mobileWidthSmall}) {
      width:calc(100%);
    }
    div:nth-child(1){
      width:35px;
      @media (max-width: ${variable.tabletWidth}) {
        flex-direction:column;
        margin-bottom:20px;
    }
    }
    div:nth-child(2){
      margin-left:20px;
      width:calc(100% - 55px);
      @media (max-width: ${variable.tabletWidth}) {
        margin-left:0px;
        width:100%;
      }
    }
    *{
      color:white !important;

    }
    }
  }
  .network-services{
    max-width:1060px;
    margin:110px auto 0px auto;
    @media (max-width: ${variable.tabletWidth}) {
      margin:55px auto 0px auto;
      }
    h2{
      margin-top:0px;
      margin-bottom:50px;

    }
    .network-sideby{
      display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    h3{
      color:#254F7B;
      margin-top:0px;
      font-size:32px;
      line-height:39px;
      font-weight:900;
      @media (max-width: ${variable.tabletWidth}) {
      font-size:25px;
      line-height:1.2;
    }
    }
    ul{
      padding:0px;
      *{
        color:white;
      }
      li{
        font-size:16px;
        line-height:20px;
        font-weight:400;
        background: url(${iconCheck}) no-repeat left 10px;
        padding: 8px 0px 8px 40px;
        background-position:left center;
        /* reset styles (optional): */
        list-style: none;
        margin: 0;
        margin-bottom:15px;
              @media (max-width: ${variable.tabletWidth}) {
                font-size:14px;
      line-height:1.2;
    }
      }
    }
    .management-copy{
      font-size:16px;
        line-height:20px;
        font-weight:400;
        color:white !important;
        @media (max-width: ${variable.tabletWidth}) {
      font-size:14px;
      line-height:1.2;
    }
      *{
        color:white !important;
      }
    }
    .network-monitoring{
      padding:40px;
      width:calc(50% - 12.5px);
      background: linear-gradient(180deg, rgba(24, 34, 51, 0.8) 3.54%, rgba(0, 0, 0, 0) 72.37%), #031528;
background-blend-mode: exclusion, normal;
border: 3px solid #13406F;
box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.25), inset 0px 4px 6px rgba(0, 0, 0, 0.25);
@media (max-width: ${variable.mobileWidth}) {
  width:100%;
  margin-bottom:40px;
}
    }
    .network-management{
      padding:40px;
      width:calc(50% - 12.5px);
      background: linear-gradient(180deg, rgba(24, 34, 51, 0.8) 3.54%, rgba(0, 0, 0, 0) 72.37%), #031528;
background-blend-mode: exclusion, normal;
border: 3px solid #13406F;
box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.25), inset 0px 4px 6px rgba(0, 0, 0, 0.25);
@media (max-width: ${variable.mobileWidth}) {
  width:100%;
}
    }
    }
  }
  #nm-form{
        ._form_1{
        form._form_1{
          display:block !important;
          background-color:transparent !important;
          max-width:688px;
          margin:0 auto;              
          @media (max-width: ${variable.mobileWidth}) {
            padding:0px;
            margin-top:20px;
          }
        input::placeholder{
          color:transparent !important;

        }
        button._submit{
          background-color:#AC2330 !important;
          width:100%;
          padding:20px 10px !important;
          text-align:center;
          border-radius:0px;
          font-size:26px;
          font-weight:800;
          margin-top:30px;
        }
        textarea{
          height:300px;
          background: #FFFFFF;
          border: 1px solid #D9D9D9;
           border-radius:0px;
           display:block;
        }
        label{
          color:white !important;
          *{
            color:white !important;
          }
        }
        input{
          background: #FFFFFF;
border: 1px solid #D9D9D9;
border-radius:0px;
padding:20px 10px;
        }
        ._form_element{
          &:nth-child(1){
            width:calc(50% - 10px);
            float:left;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(2){
            width:calc(50% - 10px);
            float:right;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(3){
            width:calc(50% - 10px);
            float:left;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
          &:nth-child(4){
            width:calc(50% - 10px);
            float:right;
            position:relative;
            z-index:99;
          @media (max-width: ${variable.mobileWidth}) {
           float:none;
           width:100%;
          }
            &:after{
              content:"";
              clear:both;
            }
          }
        }
        }
      }

    }
    .nm-contact-outer{
        margin-top:60px;
        color:white !important;
        position:relative;
        z-index:2;
        h2{
          width:100%;
          text-align:center;
        }
        .nm-description{
          text-align:center;
        }
        *{
          color:white !important;
        }
        .nm-contact-container{
          border: 20px solid rgb(17, 32, 52);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 22px;
    padding: 20px 20px 50px;
    background: radial-gradient(41.93% 41.93% at 50% 50%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%), rgb(7, 24, 42);
    background-blend-mode: overlay, normal;
    @media (max-width: ${variable.mobileWidth}) {
  /* background:rgb(9, 25, 42); */
     /* ff 3.6+ */
     background:-moz-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%); 

/* safari 5.1+,chrome 10+ */
background:-webkit-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

/* opera 11.10+ */
background:-o-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

/* ie 6-9 */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#254E7B', endColorstr='#254E7B', GradientType=1 );

/* ie 10+ */
background:-ms-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

/* global 94%+ browsers support */
background:linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);
}
        }
      }

      .nm-footer-outer{
        background: #09192a;
        margin-top:150px;
        color: white !important;
        padding-bottom:80px;
        z-index:2;
        position:relative;
        *{
          color:white !important;
        }
        .nm-footer-grid{
          display:flex;
          justify-content:space-between;
          flex-wrap:wrap;
          h3{
            font-size:22px;
            font-weight:700;
          }
          .nm-footer-growth{
            width:40%;
            @media (max-width: ${variable.mobileWidth}) {
            width:48%;
          }
            h4{
              font-size:14px;
              font-weight:400;
              margin-top:0px;
            }
            img{
              max-width:148px;
              margin-top:10px;
            }
          }
          .nm-footer-locations{
            width:35%;
            @media (max-width: ${variable.mobileWidth}) {
            width:48%;
            margin-bottom:40px;
          }
            strong{
              display:block;
              font-weight:700;
            }
            div{
              max-width:225px;
              margin-bottom:20px;
              font-size:14px;
              font-weight:400;
            }
          }
          .nm-footer-advice{
            width:25%;
            @media (max-width: ${variable.mobileWidth}) {
            width:100%;
            margin-bottom:40px;

          }
            a{
              font-size:14px;
              font-weight:400;
            }
            h4{
              font-size:14px;
              font-weight:400;
              margin-top:0px;
            }
            > div{
              margin-bottom:10px;
            }
            .nm-mail{
              display:flex;
              align-items:center;
              word-break:break-all;
              div{
                margin-right:15px;
                width:32px;
              }
              a{
                width: calc(100% - 47px);
                font-size:16px;
              @media (max-width: ${variable.mobileWidth}) {
                  font-size:16px;
                }
              }
            }
            .nm-phone{
              display:flex;
              align-items:center;
              div{
                margin-right:15px;
                width:32px;
              }
              a{
                width: calc(100% - 47px);
                font-size:16px;
                @media (max-width: ${variable.mobileWidth}) {
                  font-size:14px;
                }
              }
            }
            .nm-fax{
              display:flex;
              align-items:center;
              font-size:16px;
              @media (max-width: ${variable.mobileWidth}) {
                  font-size:14px;
                }
              div{
                margin-right:15px;
                width:32px;
              }
              a{
                width: calc(100% - 47px);
                @media (max-width: ${variable.mobileWidth}) {
                  font-size:16px;
                }
              }
            }
          }
        }
      }
      .support-hour{
        width:100%;
        max-width:575px;
        background: #0b1e32;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        border-bottom-right-radius:50px;
        border-bottom-left-radius:50px;
        margin:0 auto;
        padding:20px;
        position:relative;
        top:-80px;
        h2{
          margin:0px;
          margin-bottom:10px;
          width:100%;
          text-align:center;
          font-weight:600;
        }
        .support-hours-dates{
          background:#071628;
          border-bottom-right-radius:50px;
        border-bottom-left-radius:50px;
        padding:25px 30px;
        display:flex;
        justify-content:space-between;
        font-size: 24px;
    font-weight: 600;
    @media (max-width: ${variable.mobileWidth}) {
                  flex-direction:column;
    }
        > div{
          display:flex;
          align-items:center;
          @media (max-width: ${variable.mobileWidth}) {
                  &:nth-child(1){
                    margin-bottom:20px;
                  }
    }
          &:before{
          content:"";
          background-color:#620217;
          border:5px solid #990828;
          border-radius:100%;
          margin-right:15px;
          width:17px;
          height:17px;
        }
        }

        }
      }
      .gear-line-container{
        position: relative;

      }
.center-gear-line{
  display:flex;
  justify-content:center;
  width: 100%;
    height: 100%;
    position: absolute;
    /* top: 57%; */
    align-items: center;
    
    /* @media (max-width: ${variable.tabletWidth}) {
      top: 55%;
    }
    @media (max-width: 950px) {
      top: 55%;
    }
    @media (max-width: 875px) {
      top: 57%;
    }
    @media (max-width: 860px) {
      top: 58%;
    }
    @media (max-width: ${variable.mobileWidth}) {
      top: 48%;
    }
    @media (max-width: 730px) {
      top: 48%;
    }
    @media (max-width: 720px) {
      top: 49%;
    }
    @media (max-width: 700px) {
      top: 46.5%;
    }
    @media (max-width: 660px) {
      top: 49.5%;
    }
    @media (max-width: ${variable.mobileWidthSmall}) {
      top: 45.2%;
    }
    @media (max-width: 530px) {
      top: 45.7%;
    }
    @media (max-width: 480px) {
      top: 46%;
    }
    @media (max-width: 460px) {
      top: 46.7%;
    } */
  div{
    background: #041323;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.7), 0px 1px 1px #FFFFFF;
    transform: matrix(1, 0, 0, -1, 0, 0);
    width:80%;
    height:2px;
    mix-blend-mode: overlay;
    position: relative;

    top: 3%;

    @media (max-width: ${variable.tabletWidth}) {
      top: 13%;
    }
    @media (max-width: ${variable.mobileWidth}) {
      top: -2%;
    }
  }

}
      .new-center-gear{
        position:relative;
        aspect-ratio: 16/7;
        z-index: 1;
    display: flex;
    justify-content: center;
    @media (max-width: ${variable.mobileWidth}) {
      aspect-ratio:auto;
      display:flex;
    /* ff 3.6+ */
    background:-moz-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

    /* opera 11.10+ */
    background:-o-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#254E7B', endColorstr='#254E7B', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);

    /* global 94%+ browsers support */
    background:linear-gradient(0deg, rgba(37, 78, 123, 0) 0%, rgba(37, 78, 123, 0.41) 50%, rgba(37, 78, 123, 0) 101%);
    }
    img.desktop-center-gear{
      height: 154%;
    position: absolute;
    top: -27%;
    @media (max-width: ${variable.tabletWidth}) {
        display:none;
      }
    }
    img.tablet-center-gear{
      height: 130%;
    position: absolute;
    top: -5%;
    display:none;
    @media (max-width: ${variable.tabletWidth}) {
        display:block;
      }
          @media (max-width: ${variable.mobileWidth}) {
        display:none;
      }
    }
    img.mobile-center-gear{
      height: auto
    position: relative;
    width:100%;
    display:none;
    @media (max-width: ${variable.mobileWidth}) {
        display:block;
      }
    }
   }

`

export const addActive = id => {
  if (typeof window !== "undefined") {
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.src =
      "https://prescriptivesolutions.activehosted.com/f/embed.php?id=" + id
    document.getElementsByTagName("head")[0].appendChild(script)
  }
}
const Page = ({ data }) => {
  //   const prismicContent = data.page.allPas.edges[0]
  //   if (!prismicContent) return null
  const node = data.page
  const leadership = data.leadership
  const podcast = data.podcast
  const job = data.job
  const site = data.site
  const podinfo = data.podinfo
  const blog = data.blog
  //   const site = data.site.allSite_informations.edges[0].node

  return (
    <Layout slug={"network-monitoring"}>
      <SEO site={site} page={node} />
      <PageStyle style={{ minHeight: "800px" }}>
        <div className="nm-container-inner">
          <div className="background-lights"></div>
          <div className="nm-hero-outer">
            <Container>
              <h2>
                Network Monitoring and Management Solutions that give you{" "}
                <span className="nm-peace">peace of mind</span>
              </h2>
              <div className="contact-link-container">
                <AnchorLink href="#contact-form" className="cta-button">
                  Contact Us
                </AnchorLink>
              </div>
            </Container>
          </div>
          <div className="nm-causes-outer">
            <Container>
              <div className="nm-causes-inner">
                <h2>
                  Causes of
                  <span className="nm-peace"> Network Failures</span>
                </h2>
                <div className="nm-cause-org">
                  Organizations tend to underestimate the time and expertise
                  required to manage and maintain network monitoring solutions.
                  You need a partner who can address the root cause of network
                  issues, not just the symptoms. Learn more about the most
                  common causes of network failure and how we can help.
                </div>

                <div className="center-gear-container">
                  <div className="centerGearOverlay"></div>
                  <div className="centerGearOverlay"></div>
                  <div className="centerGearOverlay"></div>
                  <div className="causes-top-box-container">
                    <div className="causes-top-box">
                      <h2>Maintenance Woes</h2>
                      <div className="causes-top-copy">
                        Monitoring solutions require upgrades, patches, or
                        configuration mods that may have negative side effects.
                      </div>
                    </div>
                    <div className="causes-top-box">
                      <h2>Growth Pains</h2>
                      <div className="causes-top-copy">
                        Simply adding new devices and decommissioning obsolete
                        ones can cause unintended side effects if not handled
                        properly.
                      </div>
                    </div>
                    <div className="causes-top-box">
                      <h2>System Complexity</h2>
                      <div className="causes-top-copy">
                        Network solutions are comprised of many interdependent
                        parts. Maximizing uptime requires deep understanding of
                        how these pieces fit together.
                      </div>
                    </div>
                    <div className="causes-top-box">
                      <h2>Bandaid Failure</h2>
                      <div className="causes-top-copy">
                        Monitoring solutions should not only help “fix” a
                        problem, they should provide insight into how to avoid
                        future issues.
                      </div>
                    </div>
                  </div>
                  <div className="gear-line-container">
                    <div className="center-gear-line">
                      <div></div>
                    </div>
                    <div className="new-center-gear">
                      <img className="desktop-center-gear" src={centerGear} />
                      <img
                        className="tablet-center-gear"
                        src={centerGearTablet}
                      />
                      <img
                        className="mobile-center-gear"
                        src={centerGearMobile}
                      />
                      <div className="center-gear-middle-middle-new">
                        Benefits of a Professionally Deployed and Managed
                        Network Monitoring Solution
                      </div>
                    </div>
                  </div>
                  <div className="causes-bottom-box-container">
                    <div className="causes-bottom-box">
                      <div>
                        <img src={insights} />
                      </div>
                      <div>
                        Insight into the availability and performance of your
                        network infrastructure
                      </div>
                    </div>
                    <div className="causes-bottom-box">
                      <div>
                        <img src={freedom} />
                      </div>
                      <div>
                        Freedom to reallocate resources toward other tasks
                      </div>
                    </div>
                    <div className="causes-bottom-box">
                      <div>
                        <img src={clear} />
                      </div>
                      <div>
                        Clear understanding of trends, allowing you to make
                        informed decisions
                      </div>
                    </div>
                    <div className="causes-bottom-box">
                      <div>
                        <img src={detection} />
                      </div>
                      <div>
                        Earlier detection and faster time to resolution for
                        issues that may arise
                      </div>
                    </div>
                  </div>
                </div>
                <div className="network-services">
                  <h2>Our Services</h2>
                  <div className="network-sideby">
                    <div className="network-monitoring">
                      <h3>Network Monitoring</h3>
                      <ul>
                        <li>
                          Monitoring of network devices and connectivity using
                          state-of-the-art monitoring technologies
                        </li>
                        <li>
                          Coordination with third party providers—e.g.,
                          broadband, phone, hardware—on repair work
                        </li>
                        <li>
                          Configuration tracking and change management for
                          network devices
                        </li>
                        <li>
                          Alert response, including performance of initial
                          triage and notification to appropriate personnel and
                          third parties for remediation
                        </li>
                        <li>
                          Detailed monthly reports including tickets and alerts
                          generated, utilization trends, recommended firmware
                          upgrades and patches
                        </li>
                      </ul>
                    </div>
                    <div className="network-management">
                      <h3>Network Management</h3>
                      <div className="management-copy">
                        Everything included under Network Monitoring, plus:
                      </div>
                      <ul>
                        <li>Firmware updates and patches</li>
                        <li>
                          Consistently executed changes and updates based on
                          defined policies
                        </li>
                        <li>
                          Port configurations, VLANs, routers, firewall rules
                          and settings
                        </li>
                        <li>VPN management and support</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <div className="nm-contact-outer" id="contact-form">
            <Container>
              <div className="nm-contact-container">
                <div className="nm-contact-inner">
                  <h2>Didn't find what you were looking for?</h2>
                  <div className="nm-description">
                    For more information, fill out the form below.
                  </div>
                  <div id="nm-form">
                    <div className={"_form_1"}>{addActive("1")}</div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="nm-footer-outer">
            <Container>
              <div className="support-hour">
                <h2>Support Hour Options</h2>
                <div className="support-hours-dates">
                  <div className="support-weekday">Weekdays 8AM - 6PM</div>
                  <div className="support-three">24/7 365</div>
                </div>
              </div>
              <div className="nm-footer-grid">
                <div className="nm-footer-growth">
                  <h3>Fast Growth 150</h3>
                  <h4>For Three Years Running!</h4>
                  <img src={growth} />
                </div>
                <div className="nm-footer-locations">
                  <h3>Locations</h3>
                  <div>
                    <strong>SAN ANTONIO</strong>
                    20079 Stone Oak Pkwy #1105 San Antonio, TX 78258
                  </div>
                  <div>
                    <strong>HOUSTON</strong>
                    2626 Spring Cypress Rd. Ste 63 Spring, TX 77388
                  </div>
                  <div>
                    <strong>TORONTO</strong>
                    325 Front Street West 2nd Floor #2035 Toronto, Ontario M5V
                    2Y1 Canada
                  </div>
                </div>
                <div className="nm-footer-advice">
                  <h3>Looking for Expert Advice?</h3>
                  <h4>We’re happy to help. Reach us at:</h4>
                  <div className="nm-mail">
                    <div>
                      <img src={mail} />
                    </div>

                    <a href="mailto:info@prescriptive.solutions">
                      info@prescriptive.solutions
                    </a>
                  </div>
                  <div className="nm-phone">
                    <div>
                      <img src={phone} />
                    </div>
                    <a href="tel:888.252.3182">888.252.3182</a>
                  </div>
                  <div className="nm-fax">
                    {" "}
                    <div>
                      <img src={fax} />
                    </div>
                    210.961.8108
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </PageStyle>
    </Layout>
  )
}
export default Page

export const postQuery = graphql`
  query PageBySlugNetworkMonitoring($uid: String!) {
    job: allPrismicJob {
      nodes {
        uid
        data {
          description {
            html
          }
          location {
            text
          }
          teaser_description {
            html
          }
          title {
            text
          }
        }
      }
    }
    podinfo: allPrismicPodcast(
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        uid
        data {
          buzzsprout_id {
            text
          }
          title {
            text
          }
          podcast_image {
            gatsbyImageData
          }
        }
      }
    }
    podcast: allBuzzsproutPodcastEpisode(filter: { private: { eq: false } }) {
      nodes {
        title
        slug
        artwork_url
        artist
        description
        summary
        private
      }
    }
    leadership: allPrismicLeadership {
      nodes {
        data {
          bio {
            html
            raw
          }
          linkedin {
            url
          }
          name {
            text
          }
          photo {
            url

            gatsbyImageData
          }
          title {
            text
          }
          twitter {
            url
          }
        }
      }
    }
    page: prismicPa(uid: { eq: $uid }) {
      uid
      id
      type
      data {
        meta_title
        meta_description
        donotindex
        webinar
        typeform_url {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicPaDataBodySlideshow {
            id
            primary {
              background_color
              section_title {
                text
              }
            }
            slice_type
            items {
              image {
                gatsbyImageData
              }
              image_copy {
                html
              }
            }
          }
          ... on PrismicPaDataBodyBasicSection {
            id
            slice_type
            items {
              sidebar_block_reference {
                document {
                  ... on PrismicBlocks {
                    id
                    data {
                      block_title {
                        text
                      }
                      body {
                        ... on PrismicBlocksDataBodyBasicSection {
                          id
                          slice_type
                          primary {
                            background_color
                            background_video {
                              url
                            }
                            youtube_background {
                              embed_url
                            }
                            background_image {
                              gatsbyImageData
                            }
                            content {
                              raw
                            }
                            font_color
                            h1_title
                            section_title {
                              text
                            }
                            slice_id {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            primary {
              section_title {
                text
              }
              h1_title
              font_color
              background_color
              slice_id {
                text
              }
              background_image {
                gatsbyImageData
              }
              background_video {
                url
              }
              youtube_background {
                embed_url
              }
              content {
                html
                raw
              }
            }
          }
          ... on PrismicPaDataBodyEntityQuery {
            id
            slice_type
            primary {
              entity_type
              number_of_entities
              slice_id {
                text
              }
              section_title {
                text
              }
              background_color
              background_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPaDataBodyBlockReference {
            id
            primary {
              block_reference {
                document {
                  ... on PrismicBlocks {
                    id
                    data {
                      body {
                        ... on PrismicBlocksDataBodyColumnsSection {
                          id
                          slice_type
                          primary {
                            background_color
                            slice_id {
                              text
                            }
                            background_image {
                              gatsbyImageData
                            }
                            column_count
                            font_color
                            h1_title
                            section_title {
                              text
                            }
                          }
                          items {
                            content {
                              raw
                            }
                          }
                        }
                        ... on PrismicBlocksDataBodyBasicSection {
                          id
                          slice_type
                          primary {
                            background_color
                            background_image {
                              gatsbyImageData
                            }
                          }
                          items {
                            sidebar_block_reference {
                              document {
                                ... on PrismicBlocks {
                                  id
                                  data {
                                    body {
                                      ... on PrismicBlocksDataBodyBasicSection {
                                        id
                                        slice_type
                                        primary {
                                          background_color
                                          background_video {
                                            url
                                          }
                                          youtube_background {
                                            embed_url
                                          }
                                          background_image {
                                            gatsbyImageData
                                          }
                                          content {
                                            html
                                            raw
                                          }
                                          font_color
                                          h1_title
                                          section_title {
                                            text
                                          }
                                          slice_id {
                                            text
                                          }
                                        }
                                      }
                                    }
                                    block_title {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PrismicBlocksDataBodyLeftRightSection {
                          id
                          slice_type
                          primary {
                            active_campaign_form_number
                            embed {
                              raw
                            }
                            section_title {
                              text
                            }
                            left_background_image {
                              gatsbyImageData
                            }

                            left_content {
                              html
                              raw
                            }
                            right_background_image {
                              gatsbyImageData
                            }
                            right_content {
                              html
                              raw
                            }
                            right_embed {
                              raw
                            }
                            section_title {
                              text
                            }
                            slice_id {
                              text
                            }
                          }
                        }
                      }
                      block_title {
                        text
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicPaDataBodyHero {
            id
            slice_type
            primary {
              background_image {
                gatsbyImageData
              }
              font_color
              min_height
              hero_title {
                text
              }
            }
          }
          ... on PrismicPaDataBodyColumnsSection {
            id
            slice_type
            primary {
              background_color
              column_count
              slice_id {
                text
              }
              background_image {
                gatsbyImageData
              }
              section_title {
                text
              }
              h1_title
              font_color
            }
            items {
              content {
                html
                raw
              }
            }
          }

          ... on PrismicPaDataBodyLeftRightSection {
            id
            slice_type
            primary {
              left_width
              right_width
              slice_id {
                text
              }
              section_title {
                text
              }
              embed {
                text
              }
              right_embed {
                text
              }
              active_campaign_form_number
              right_active_campaign_form_number
              right_content_above_form {
                raw
              }
              left_background_image {
                gatsbyImageData
              }
              right_background_image {
                gatsbyImageData
              }
              right_content {
                html
                raw
              }
              full_width_content {
                raw
              }
              left_content {
                html
                raw
              }
            }
          }
        }
      }
    }
    site: allPrismicSiteInformation {
      nodes {
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          description {
            text
          }
          site_url {
            text
          }
          site_title {
            text
          }
          twitter_author {
            text
          }
        }
      }
    }
    blog: allPrismicBlogPost(
      sort: { order: DESC, fields: data___release_date }
    ) {
      nodes {
        uid
        data {
          release_date(formatString: "MMM D Y")
          teaser {
            html
          }
          author {
            text
          }
          title {
            text
          }
          main_image {
            url

            gatsbyImageData
          }
        }
      }
    }
  }
`
